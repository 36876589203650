
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { FileModel, SimpleDocumentModel } from 'media/models';
import { isNativeAppWebview } from 'utils/general';
import { SetDisabled } from 'types';
import { SubscriptionsModel } from './models';

export const getSubscriptions = (): Promise<SubscriptionsModel[]> => (
  axios.get(getApiUrl('/equity/subscription-certificates/'))
    .then(({ data }) => SubscriptionsModel.fromResponse(data))
    .catch(console.error)
);

export const getSubscription = (subscriptionId: string): Promise<SubscriptionsModel> => (
  axios.get(getApiUrl(`/equity/subscription-certificates/${subscriptionId}/`))
    .then(({ data }) => SubscriptionsModel.fromResponse(data))
    .catch(console.error)
);

export const confirmSubscriptionPayment = ({
  subscriptionId,
  setDisabled,
}: {
  subscriptionId: string,
  setDisabled: SetDisabled
}): Promise<void> => (
  axios.patch(getApiUrl(`/equity/subscription-certificates/${subscriptionId}/confirm-payment/`))
    .then(() => { setDisabled(false); })
    .catch((error) => { console.error(error); setDisabled(false); })
);

export const getSubscriptionDocuments = (contractId?: number) => {
  if (!contractId) {
    throw Error('Subscription ID is missing');
  }
  const contractIdStr = contractId.toString();
  const queryParams = {
    related_object_model: 'contract',
    related_object_id: contractIdStr,
    subscription_id: contractIdStr,
  };

  return axios.get(getApiUrl('/new-documents/', queryParams))
    .then(({ data }) => SimpleDocumentModel.fromArrayResponse(data.results))
    .catch(console.error);
};

export const onFileDownload = (fileId: number, documents: SimpleDocumentModel[]) => {
  let file: FileModel | undefined;
  let documentId: FileModel | undefined;
  documents.forEach((document) => {
    const foundFile = document.files.find((docFile) => docFile.id === fileId);
    if (foundFile) {
      file = foundFile;
      documentId = document.id;
    }
  });
  if (!file || !documentId) {
    console.error(`File with id ${fileId} not found`);
    return;
  }

  const url = getApiUrl(`/new-documents/${documentId}/attachments/${fileId}/download/`);
  window.location.href = isNativeAppWebview ? file.makeNativeUrl(url) : url;
};
