import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { getApiUrl, getUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { isNativeAppWebview } from 'utils/general';
import { isNativeFile } from 'core/utils/typeGuards';
import { IFileModel, ISimpleDocumentModel, DocumentFormValues, DocumentSubmitValues } from './interfaces';
import { DocumentUpdateFormValues } from './interfaces/formTypes';
import { FileBase64Data, MixedFileInputValue } from './types';

export const getNativeFileUrl = (
  url: string,
  title: string,
  mediaType: string,
  blockchainDatetime?: string,
  keccak256?: string,
) => {
  const queryArgs = new URLSearchParams({ uri: url, title, media_type: mediaType });
  if (blockchainDatetime) {
    queryArgs.append('blockchain_datetime', blockchainDatetime);
  }
  if (keccak256) {
    queryArgs.append('keccak256', keccak256);
  }
  const { nativeUrlScheme } = getGlobalContext();
  return new URL(`${nativeUrlScheme}document?${queryArgs}`).href;
};

export const getDocumentData = (values: DocumentFormValues, isUpdate = false) => {
  const { id, type, subject, date, status, files } = values;
  const data = {} as DocumentSubmitValues;
  const filesCopy = [...files];
  if (isUpdate) {
    data.files = filesCopy.filter((file) => !(file instanceof File) && !!file?.id) as IFileModel[];
    // when editing a document new documents have ids set to a stringified timestamp
    // to have a unique identifier when adding or removing them, such document ids should not be submitted
    if (typeof id !== 'string') {
      data.id = id;
    }
  }
  data.doc_type = type || 'FU';
  data.status = status || 'DRA';
  data.date = (date instanceof Date) ? date.toISOString().split('T')[0] : date;
  data.subject = subject;
  data.new_files = filesCopy.filter(isNativeFile);
  return data;
};

export const getDocumentSubmitData = (values: DocumentFormValues, isUpdate = false) => {
  const submitData = new FormData();
  const document = getDocumentData(values, isUpdate);
  const { files, new_files: newFiles, ...restData } = document;

  Object.entries(convertKeysCase(restData, snakeCase)).forEach(
    ([key, value]) => submitData.append(key, value as string),
  );

  files && files.forEach((file, fileIndex) => {
    Object.entries(file).forEach(([key, value]) => {
      submitData.append(`files[${fileIndex}]${snakeCase(key)}`, value);
    });
  });

  newFiles?.forEach((file, index) => { submitData.append(`new_files[${index}]`, file); });

  return submitData;
};

export const prepareNewDocumentData = (
  values: DocumentUpdateFormValues,
  dateFieldName = 'doc_date',
  filesFieldName = 'files',
): FormData => {
  const { date, files, subject } = values;
  const documentData = new FormData();

  documentData.append('subject', subject);
  documentData.append('doc_type', 'FU');
  documentData.append(dateFieldName, date);
  documentData.append('status', 'FIN');
  files.forEach((file) => { documentData.append(filesFieldName, file); });

  return documentData;
};

export const addMultipleDocuments = ({ documents, submitData, isUpdate = false, fieldName = 'documents' }: {
  documents: DocumentFormValues[];
  submitData: FormData;
  isUpdate?: boolean;
  fieldName?: string;
}) => {
  const docs = documents.map((doc) => getDocumentData(doc, isUpdate));
  docs.forEach((doc, docIndex) => {
    const { new_files: newFiles, files, ...data } = doc;
    Object.entries(data).forEach(
      ([key, value]) => submitData.append(`${fieldName}[${docIndex}]${key}`, value as string),
    );
    newFiles && newFiles.forEach((newFile, newFileIndex) => {
      submitData.append(`${fieldName}[${docIndex}]new_files[${newFileIndex}]`, newFile);
    });
    files && files.forEach((file, fileIndex) => {
      Object.entries(file).forEach(([key, value]) => {
        submitData.append(`${fieldName}[${docIndex}]files[${fileIndex}]${snakeCase(key)}`, value);
      });
    });
  });
  return submitData;
};

export const onPdfDownload = (downloadLink: string, title: string) => {
  window.location.href = isNativeAppWebview
    ? getNativeFileUrl(downloadLink, `${title}.pdf`, 'application/pdf')
    : downloadLink;
};

const agreements = {
  user: 'user_agreement',
  corporate: 'corporate_user_agreement',
};
export const onAgreementDownload = (type?: 'user' | 'corporate') => {
  const path = type ? `/agreements/${type}/` : '/account/agreement';
  let title: string = '';
  if (type) {
    title = agreements[type];
  } else {
    const { activeEntity: { isCompany } } = getGlobalContext();
    title = isCompany ? agreements.corporate : agreements.user;
  }

  onPdfDownload(getUrl(path), title);
};

export const onFileDownload = (fileId: number, documents: ISimpleDocumentModel[], urlBase: string) => {
  let files: IFileModel[] = [];
  documents.forEach((document) => {
    const docFiles = document.files;
    if (docFiles) {
      files = [...files, ...docFiles];
    }
  });
  const file = files.find((docFile) => docFile.id === fileId);
  if (!file) {
    console.error(`File with id ${fileId} not found`);
    return;
  }
  const url = getApiUrl(`${urlBase}files/${fileId}/download/`);
  window.location.href = isNativeAppWebview ? file.makeNativeUrl(url) : url;
};

export const fileToBase64 = (file: File): Promise<FileBase64Data> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({
    name: file.name,
    file_data: (reader.result as string).split(',')[1],
  });
  reader.onerror = (error) => reject(error);
});

/**
 * Parses a mixed file input and returns a promise that resolves to an array of FileBase64Data or numbers.
 *
 * @param {MixedFileInputValue} files - An array of mixed file input values,
 * which can be either File objects or objects representing saved files.
 * @returns {Promise<(FileBase64Data | number)[]>} - A promise that resolves to an array of FileBase64Data or numbers.
 *
 * The function maps over the input files
 * and converts each File object to its base64 representation using the fileToBase64 function.
 * If the input is not a File object, it returns the id property of the input.
 */
export const parseMixedFileInput = (
  files: MixedFileInputValue,
): Promise<(FileBase64Data | number)[]> => Promise.all((files).map((file) => {
  if (file instanceof File) {
    return fileToBase64(file);
  }
  return file.id;
}));
