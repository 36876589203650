import axios from 'core/axios';
import { updateUnsignedTransaction } from 'core/page/transactionSigning/utils';
import { isNativeAppWebview } from 'utils/general';

export const onFileDownload = (fileId, documents, path) => {
  let files = [];
  documents.forEach((document) => { files = [...files, ...document.files]; });
  const file = files.find((docFile) => docFile.id === fileId);
  const url = `${path}files/${fileId}/download/`;
  window.location = isNativeAppWebview ? file.makeNativeUrl(url) : url;
};

export const onCancel = (path, dispatch, setDisabled) => {
  axios.delete(path)
    .then(() => { dispatch({ type: 'removed' }); })
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onConfirm = (setDisabled, path, nativeSigningUrl, dispatch) => {
  const { unsignedTransaction: { signingStatusOld } } = window.trustwise;
  if (!window.trustwise.twex.enableTxConfirm) {
    window.trustwise.twex.enableTxConfirm = () => { setDisabled(false); };
  }
  signingStatusOld && signingStatusOld('unknown');
  if (nativeSigningUrl) {
    window.location = nativeSigningUrl;
  } else {
    axios.post(`${path}custodial/sign/`)
      .then(() => { dispatch({ type: 'status_updated', data: 'accepted' }); })
      .catch(console.error)
      .finally(() => { setDisabled(false); });
  }
};

export const onSkip = (path, dispatch, setDisabled) => {
  axios.post(`${path}skip/`)
    .then(() => {
      updateUnsignedTransaction({ isProcessed: true });
      dispatch({ type: 'reset' });
    })
    .catch((error) => {
      console.error(error);
      !!setDisabled && setDisabled(false);
    });
};

export const onSeeDetails = (path, dispatch, setDisabled) => {
  axios.post(`${path}notify-signing/`)
    .then(() => { dispatch({ type: 'switch_modals' }); })
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};
